<template>
  <div class="tui tuim ui middle aligned center aligned grid">
    <div class="tui tuim column">
      <t-auth-header
        :description="`${(!emailSent) ? 'Para recuperar sua senha, insira o seu endereço de e-mail' : ''}`"
      />

      <div v-if="!emailSent" class="tui tuim ui card">
        <div class="content left aligned spacer">
          <form :class="`tui tuim ui large form ${error ? 'error' : ''}`" @submit.prevent="recoveryPassword()">
            <div :class="`field ${error ? 'error' : ''}`">
              <label for="authEmail">E-mail</label>
              <input type="text" id="authEmail" name="email" v-model="form.email" placeholder="exemplo@e-mail.com.br" />
            </div>

            <button
              type="submit"
              :class="`tui tuim ui primary large fluid ${isLoading ? 'loading' : ''} ${isLoading ? 'disabled' : ''} button`"
              v-text="`Enviar`"
              :disabled="isLoading"
            />
          </form>
        </div>
      </div>

      <div class="recovered-successfully" v-if="emailSent">
        <h2>Pedido enviado com sucesso!</h2>
        <p>Uma mensagem com um link para criar uma nova senha foi enviada para o seu endereço de e-mail.</p>
        <p>Caso ainda não tenha recebido, procure na sua caixa de spam, ou na lixeira.</p>
        <router-link :to="{ name: 'auth.login' }" class="tui tuim ui positive large fluid button">Entrar</router-link>
      </div>
      <p v-else>
        Já é cadastrado? <router-link :to="{ name: 'auth.login' }" v-text="`Entrar`" />
      </p>
    </div>
  </div>
</template>

<script>
import TAuthHeader from '@/components/auth/header.vue'

export default {
  name: 'TAuthNewPassword',
  title: ({ title }) => title,
  data () {
    return {
      title: `Recuperar senha`,
      form: {
        email: ''
      },
      emailSent: false,
      isLoading: false,
      error: false
    }
  },
  methods: {
    recoveryPassword () {
      this.isLoading = true
      setTimeout(() => {
        this.emailSent = true
        this.isLoading = false
      }, 1500)
    }
  },
  components: {
    TAuthHeader
  }
}
</script>

<style lang="scss">
.recovered-successfully {
  h2 {
    font-weight: 800;
    font-size: 24px;
    line-height: 26px;
    color: #78E5A4;
    margin: 60px 0;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: #7A7A7A;
    margin-bottom: 30px;
  }
}
</style>
